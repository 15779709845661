import { AboutHero } from '@/AboutPages/AboutHero';
import { Article } from '@/Article';
import { Seo } from '@/layout/Seo';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const AlexTwenge: Page = () => {
    return (
        <Fragment>
            <Seo
                title="Dr. Alex Twenge – Integrate Institute"
                description="Meet dr. Alex Twenge, chiropractor in Burnsville Mennesota"
            />
            <AboutHero>
                <StaticImage
                    src="../../../assets/images/about-us/alex.jpg"
                    alt="alex twenge"
                    width={504}
                    height={568}
                />
            </AboutHero>
            <Article>
                <h1
                    css={css`
                        margin: 0;
                    `}
                >
                    Alex Twenge
                </h1>
                <h3
                    css={css`
                        margin: 0 0 32px;
                    `}
                >
                    Doctor of Chiropractic
                </h3>
                <p>
                    As a sports enthusiast, Dr. Alex has a passion for working with athletes and
                    active people of all ages. As a division one pitcher and three sport high school
                    athlete, Dr. Alex has had just about every injury in the book. During his time
                    in graduate school, Dr. Alex studied under two incredible mentors who helped him
                    develop the movement based approach that he takes. He specializes in the
                    understanding of the sequencing of the human body with application to everyday
                    life and high intensity movements. Having used this approach on everyone from
                    the professional athlete to the weekend warrior, he has educated patients on how
                    to move better!
                </p>

                <p>
                    Dr. Alex enjoys the challenges of training and competing in sports. In his free
                    time, he tries to keep up with his bride (our therapist Megan!) on the court and
                    in the weight room.
                </p>
            </Article>
        </Fragment>
    );
};

export default AlexTwenge;
